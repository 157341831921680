// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/molecules/BackFromErrorButton.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/molecules/BackFromErrorButton.tsx");
  import.meta.hot.lastModified = "1727800993562.9922";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { buttonVariants } from '../atoms/Button';
export function BackFromErrorButton() {
  return <Link to='/' className={buttonVariants({
    variant: 'primary',
    size: 'lg'
  })}>
      Go back to home
    </Link>;
}
_c = BackFromErrorButton;
var _c;
$RefreshReg$(_c, "BackFromErrorButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;